<template>
  <div class="mt-10">
    <!-- content -->
    <div class="max-width mt-14">
      <v-form
        v-model="valid"
        @submit.prevent="submitHandler"
        ref="addProductForm"
      >
        <v-row>
          <v-col cols="12" md="5" style="flex-direction: column">
            <div class="img-cover-main radius-15" :key="keyUpdated">
              <div
                class="w-100 text-center relative h-100"
                v-if="obj.image.url"
              >
                <img
                  width="100%"
                  height="100%"
                  class="object-cover mx-auto d-block radius-15"
                  :src="obj.image.url"
                  alt=""
                />
                <v-icon @click="obj.image = {}" class="close-icon" color="red"
                  >mdi-close</v-icon
                >
              </div>
              <base-upload-text
                class="w-100 h-100"
                v-if="!obj.image.url"
                :isEmpty="isEmpty"
                @change="uploadImage"
              ></base-upload-text>
            </div>
          </v-col>
          <v-col cols="12" md="7">
            <v-row>
              <!-- title -->
              <v-col cols="12">
                <base-input
                  :placeholder="$t('productName')"
                  v-model="obj.title"
                ></base-input>
              </v-col>
              <!-- weight -->
              <v-col cols="12">
                <base-input
                  :placeholder="$t('weight')"
                  v-model="obj.weight"
                  :isWeight="true"
                  itemText="title"
                  itemValue="title"
                ></base-input>
              </v-col>
              <!-- price -->
              <v-col cols="12">
                <base-input
                  :placeholder="$t('price')"
                  v-model="obj.price"
                  :isBudget="true"
                  type="number"
                ></base-input>
              </v-col>
              <!-- linkURL -->
              <v-col cols="12">
                <base-input
                  :placeholder="$t('linkURL')"
                  v-model="obj.url"
                  :rules="$helper.UrlRules"
                ></base-input>
              </v-col>
              <!-- description -->
              <v-col cols="12">
                <base-input
                  :placeholder="$t('notes')"
                  v-model="obj.description"
                  :rules="[]"
                ></base-input>
              </v-col>
            </v-row>
          </v-col>
          <!-- note -->
        </v-row>

        <div class="text-center mt-14">
          <v-btn
            class="c-btn"
            color="secondary"
            min-width="240"
            height="40"
            type="submit"
            ><span class="white--text font-300 font-20">{{
              $t("next")
            }}</span></v-btn
          >
        </div>
      </v-form>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    valid: false,
    keyUpdated: false,
    obj: { image: {} },
    isEmpty: false,
  }),
  methods: {
    uploadImage(file) {
      this.obj.image.url = URL.createObjectURL(file);
      this.obj.image.file = file;
      this.isEmpty = false;
      this.keyUpdated = !this.keyUpdated;
    },
    async submitHandler() {
      if (!this.obj.image.file) {
        this.isEmpty = true;
      } else {
        this.isEmpty = false;
      }
      let valid = this.$refs.addProductForm.validate();
      if (!valid || this.isEmpty) return;
      this.$emit("next", this.obj);
    },
  },
  beforeRouteEnter(_, _2, next) {
    if (
      localStorage.getItem("role") == "bringer" ||
      localStorage.getItem("role") == "sender"
    ) {
      next();
    } else if (localStorage.getItem("role") == "guest") {
      next("/verification");
    } else {
      next("/sign-in");
    }
  },
};
</script>

<style lang="scss" >
.img-cover-main {
  border: none;
  height: 350px;
  .close-icon {
    position: absolute;
    top: 10px;
    right: 10px;
  }
  .upload-text {
    width: 100%;
    height: 100%;
  }
}
</style>
