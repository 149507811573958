<template>
  <div class="add-shipment">
    <div class="max-width">
      <v-stepper class="mt-14" v-model="step">
        <!-- steps head -->
        <v-stepper-header
          :style="
            $vuetify.breakpoint.mobile ? '' : 'right: -20px; position: relative'
          "
        >
          <v-stepper-step :editable="false" :complete="step > 1" step="1">
            <span v-if="!$vuetify.breakpoint.mobile" class="font-22">
              {{ $t("enterProduct") }}</span
            >
          </v-stepper-step>

          <v-stepper-step step="2" :complete="step > 2">
            <span v-if="!$vuetify.breakpoint.mobile" class="font-22">
              {{ $t("reviewProduct") }}</span
            >
          </v-stepper-step>

          <v-stepper-step step="3">
            <span v-if="!$vuetify.breakpoint.mobile" class="font-22">
              {{ $t("enterAddressSend") }}</span
            >
          </v-stepper-step>
        </v-stepper-header>

        <!-- steps content -->
        <v-stepper-items>
          <!-- enter product -->
          <v-stepper-content step="1"
            ><enter-product @next="stepOneHandler"></enter-product>
          </v-stepper-content>

          <!-- review product -->
          <v-stepper-content step="2"
            ><view-details-step
              @next="step = 3"
              @back="step = 1"
              :product="objProduct"
            ></view-details-step>
          </v-stepper-content>

          <!-- address & send product -->

          <v-stepper-content step="3"
            ><enter-address-step
              @submit="addProductHandler"
              @back="step = 2"
              type="offline"
              :loading.sync="loading"

            ></enter-address-step>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </div>

    <!-- new product dialog -->
    <v-dialog v-model="addDialog" max-width="400" content-class="white">
      <add-shipment
        :title="$t('productSuccessfullyAdded')"
        :goTo="$t('goToMyProducts')"
        @close="$router.push('/profile/products')"
      ></add-shipment>
    </v-dialog>
  </div>
</template>

<script>
import AddShipment from "../../../components/dialogs/AddShipment.vue";
import EnterAddressStep from "../components/EnterAddress.vue";
import ViewDetailsStep from "../components/ViewDetails";
import EnterProduct from "./EnterProduct.vue";
export default {
  components: {
    EnterAddressStep,
    ViewDetailsStep,
    EnterProduct,
    AddShipment,
  },
  data: () => ({
    addDialog: false,
    step: 1,
    objProduct: { image: {} },
    loading: false,
  }),
  methods: {
    stepOneHandler(value) {
      this.objProduct = { ...value };
      this.step = 2;
    },
    async addProductHandler(value) {
      let formData = new FormData();
      formData.append("title", this.objProduct.title);
      formData.append("weight", this.objProduct.weight);
      formData.append("image", this.objProduct.image.file);
      formData.append("price", this.objProduct.price);
      formData.append("description", this.objProduct.description);
      formData.append("website", this.objProduct.website);
      formData.append("url", this.objProduct.url);

      if (!value.shipment_date) {
        this.$store.dispatch("showSnack", {
          text: this.$t("messages.selectDate"),
          color: "error",
        });
        return;
      }
      if (!value.category_id) {
        this.$store.dispatch("showSnack", {
          text: this.$t("messages.selectCategory"),
          color: "error",
        });
        return;
      }
      let { data } = await this.$axios.post("productOffline", formData);
      if (data.success) {
        let formDataBuy = new FormData();

        formDataBuy.append("product_id", data.data.id);
        formDataBuy.append("shipment_date", value.shipment_date);
        formDataBuy.append("country", value.country);
        formDataBuy.append("city", value.city);
        formDataBuy.append("address", value.address);
        formDataBuy.append("category_id", value.category_id);
        formDataBuy.append("latitude", value.latitude_destination);
        formDataBuy.append("longitude", value.longitude_destination);
        this.loading=true;
        let response = await this.$axios.post("buyForMe", formDataBuy);
        if (response.data.success) {
          this.loading=false;
          this.$store.dispatch("showSnack", {
            text: response.data.message,
            color: "success",
          });
          this.addDialog = true;
        } else {
          this.loading=false;
          this.$store.dispatch("showSnack", {
            text: Object.values(response.data)[0].toString(),
            color: "error",
          });
        }
      } else {
        this.$store.dispatch("showSnack", {
          text: data.message,
          color: "error",
        });
      }
    },
    async submitHandler() {
      let response = await this.addProductHandler();
      console.log("response", response);
    },
  },
};
</script>

<style lang="scss">
.add-shipment {
  .v-stepper {
    background: transparent !important;
    box-shadow: none !important;

    &__header {
      box-shadow: none !important;
      background: transparent !important;
      max-width: max-content;
      margin: auto;
      height: min-content !important;
    }
    &__step {
      border: 1px solid $grey;
      border-radius: 15px;
      padding: 8px 30px;
      transition: 0.3s;
      margin: 0px 10px;
      max-width: 280px;

      &--active {
        border: 1px solid $primary;
        background: white;
      }
    }
  }
  @media (max-width: 960px) {
    .v-stepper {
      &__step {
        padding: 8px 40px;
        span {
          margin-left: 5px;
        }
      }
    }
  }
  @media only screen and (max-width: 959px) {
    .v-stepper:not(.v-stepper--vertical) .v-stepper__label {
      display: flex !important;
    }
  }
}
</style>
